.checkbox {
	align-items: center;
	display: flex;
	gap: rem(20px);
	input {
		opacity: 0;
		position: absolute;
	}
	&__fake {
		align-items: center;
		border: rem(1px) solid rgba(0, 0, 0, 0.4);
		border-radius: rem(2px);
		display: flex;
		flex: 1 0 auto;
		justify-content: center;
		height: rem(20px);
		width: rem(20px);
	}
	input:checked + &__fake {
		background: $text-blue-green;
		border: rem(1px) solid $text-blue-green;
	}
	&__label {
		color: rgba(0, 0, 0, 0.4);
		font-size: rem(14px);
		line-height: rem(20px);
		user-select: none;
	}
}