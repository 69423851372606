.header-user {
	align-items: center;
	display: flex;
	cursor: pointer;
	min-width: 267px;
	justify-content: flex-end;
	
	&.open {
		.header-user__name {
			color: rgba(0, 0, 0, 0.4);
		}

		.header-user__arrow {
			transform: rotate(-180deg);
			opacity: 0.4;
		}
	}

	&__icon {
		align-items: center;
		border: rem(1px) solid rgba(0, 0, 0, 0.2);
		border-radius: rem(5px);
		display: flex;
		justify-content: center;
		height: rem(55px);
		width: rem(55px);

		img {
			height: rem(20px);
			width: rem(20px);
		}
	}

	&__name {
		color: #000;
		font-size: rem(18px);
		line-height: rem(25px);
		letter-spacing: -0.025em;
		margin: 0 rem(15px) 0 rem(20px);
		transition: color 0.2s;
	}

	&__arrow {
		height: rem(12px);
		width: rem(12px);
		transition: all 0.2s;
	}
}
