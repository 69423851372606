.button {
	appearance: none;
	border-radius: rem(5px);
	cursor: pointer;
	display: flex;
	flex: 1 0 auto;
	font-size: rem(18px);
	justify-content: center;
	align-items: center;
	line-height: rem(25px);
	letter-spacing: -0.015em;
	margin: 0;
	padding: rem(8px) rem(12px) rem(12px);
	transition: all .25s ease;

	&--primary {
		background: $text-blue-green;
		color: $text-invert;
		&:hover {
			background: #3ea192;
			color: $text-invert;
		}
	}
	&--secondary {
		background: none;
		border: rem(1px) solid $text-blue-green;
		color: $text-blue-green;
	}
}