.header {
  $h: &;

  padding: rem(15px) 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: $main-bg;
  z-index: 5;

  @media screen and (min-width: 1200px) {
    min-height: rem(100px);
    padding: rem(20px) 0;
  }

  &--fixed {
    box-shadow: 0 0 rem(8px) rgba(0, 0, 0, .1);
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 1200px) {
      align-items: center;
      grid-template-columns: 1fr 2fr 1fr;
    }

    &--mobile {
      @media screen and (min-width: 1200px) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @media screen and (min-width: 1200px) {
        display: grid;
      }
    }
  }

  &__logo {
    img {
      height: rem(40px);
      width: rem(85px);
      @media screen and (min-width: 1200px) {
        height: rem(70px);
        width: rem(150px);
      }
    }
  }

  &__menu {
    align-items: center;
    border: rem(1px) solid #000;
    border-radius: rem(4px);
    display: flex;
    flex-direction: column;
    gap: rem(5px);
    height: rem(40px);
    justify-content: center;
    width: rem(40px);

    span {
      background: #000;
      display: block;
      height: rem(1px);
      transform-origin: center;
      transition: all .3s ease;
      width: rem(20px);
    }

    [data-state='nav_open'] & span {
      transform: translateY(3px) rotate(45deg);
    }

    [data-state='nav_open'] & span + span {
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  &__nav {
    display: flex;
  }

  &__nav-list {
    @include list-reset;

    align-items: center;
    display: flex;
    gap: rem(40px);
  }

  &__nav-link {
    color: #000;
    font-size: rem(18px);
    letter-spacing: -0.025em;
    line-height: rem(25px);

    &--courses {
      align-items: center;
      background: #fff;
      border: rem(1px) solid rgba(0, 0, 0, 0.2);
      border-radius: rem(5px);
      display: flex;
      gap: rem(10px);
      padding: rem(15px) rem(20px);
    }

    &:focus{
      color: #000;
    }
  }

  &__nav-icon {
    display: block;
    height: rem(10px);
    width: rem(10px);
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: end;
    gap: rem(20px);
  }

  &__actions-content {
    position: relative;
  }

  &__user-menu {
    position: absolute;
    left: 0;
    bottom: rem(-22px);
    transform: translateY(100%);
    width: 100%;
    padding: rem(20px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: rem(160px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    z-index: 1;

    &:not(.open) {
      display: none;
    }
  }

  &__user-menu-list {
    @include list-reset;
  }

  &__user-menu-control {
    color: #000;
    font-size: rem(18px);
    line-height: rem(26px);
    cursor: pointer;

    & + & {
      margin-top: rem(10px);
    }

    &:hover, &:focus {
      color: #3ea192;
    }
  }
}
