@mixin nav-item {
  font-family: $ff;
  font-weight: 700;
  font-size: rem(18);
  line-height: 127.19%;
}

@mixin h1 {
  font-family: $ff;
  font-style: normal;
  font-weight: 400;
  font-size: rem(65);
  line-height: rem(82);

  @media (max-width: 920px) {
    font-size: rem(55);
    line-height: rem(62);
  }

  @media (max-width: 600px) {
    font-size: rem(40);
    line-height: rem(47);
    letter-spacing: -.6px;
  }
}

@mixin h2 {
  font-family: $ff;
  font-style: normal;
  font-weight: 400;
  font-size: rem(70);
  line-height: rem(89);

  @media (max-width: 920px) {
    font-size: rem(60);
    line-height: rem(75);
  }

  @media (max-width: 600px) {
    font-size: rem(50);
    line-height: rem(62);
  }
}

@mixin link {
  font-family: $ff;
  font-size: rem(26px);
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: rem(30px);
}

@mixin text-small {
  font-family: $ff;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
}

@mixin text {
  font-family: $ff;
  font-style: normal;
  font-weight: 300;
  font-size: rem(24);
  line-height: rem(30.5);

  @media (max-width: 920px) {
    font-size: rem(20);
    line-height: rem(24);
  }

  @media (max-width: 600px) {
    font-size: rem(16);
    line-height: rem(20);
  }
}

@mixin text-large {
  font-family: $ff;
  font-style: normal;
  font-weight: 400;
  font-size: rem(20);
  line-height: 127.19%;

  @media (max-width: 600px) {
    font-size: rem(18);
  }
}

@mixin text-gradient-large {
  font-family: $ff;
  font-style: normal;
  font-weight: 400;
  font-size: rem(50);
  line-height: rem(63.5);
  background: radial-gradient(
    245.45% 398.44% at 25.95% 0%,
    #28b192 13.44%,
    rgba(69, 211, 195, 0.973529) 46.88%,
    rgba(73, 202, 226, 0.91) 75.52%,
    #77c9da 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(190, 98, 98, 0.33);

  @media (max-width: 920px) {
    font-size: rem(35);
    line-height: rem(50);
  }

  @media (max-width: 600px) {
    font-size: rem(30);
    line-height: rem(44);
  }
}

@mixin h1_new {
  font-family: $ff;
  font-style: normal;
  font-weight: 400;
  font-size: rem(54);
  line-height: rem(68);

  @media (max-width: 920px) {
    font-size: rem(55);
    line-height: rem(62);
  }

  @media (max-width: 600px) {
    font-size: rem(40);
    line-height: rem(47);
    letter-spacing: -.6px;
  }
}

@mixin text_new {
  font-family: $ff;
  font-style: normal;
  font-weight: 300;
  font-size: rem(20);
  line-height: rem(25.4);

  @media (max-width: 920px) {
    font-size: rem(20);
    line-height: rem(24);
  }

  @media (max-width: 600px) {
    font-size: rem(16);
    line-height: rem(20);
  }
}