.test {
  //margin-top: rem(67);

  &:last-child {
    padding-bottom: rem(94);
  }

  @media (max-width: 920px) {
    //margin-top: rem(40);

    &:last-child {
      padding-bottom: rem(64);
    }
  }

  &__title {
    display: flex;
    column-gap: rem(34);

    @media (max-width: 620px) {
        column-gap: rem(16);
    }

    span {
      font-weight: 500;
      font-size: rem(24);
      line-height: 127.19%;

      @media (max-width: 920px) {
        font-size: rem(20);
      }
    }

    h2 {
      font-weight: 500;
      font-size: rem(24);
      line-height: 127.19%;

      @media (max-width: 920px) {
        font-size: rem(20);
      }
    }
  }

  &__questions {
    margin-top: rem(70);
    display: flex;
    flex-wrap: wrap;
    column-gap: rem(32);
    row-gap: rem(88);

    @media (max-width: 920px) {
      margin-top: rem(40);
      row-gap: rem(40);
    }
  }

  &__question {
    width: calc(50% - 16px);

    @media (max-width: 920px) {
      width: 100%;
    }

    &::marker {
      font-weight: 500;
      font-size: rem(16);
      line-height: 127.19%;
    }

    & fieldset {
      margin-top: rem(44);
      display: flex;
      flex-direction: column;
      row-gap: rem(20);
      margin-left: rem(-32);

      @media (max-width: 1200px) {
        margin-left: rem(-26);
      }

      @media (max-width: 920px) {
        margin-top: rem(32);
        row-gap: rem(16);
      }
    }

    &-title {
      font-weight: 500;
      font-size: rem(16);
      line-height: 127.19%;
    }

    &-subtitle {
      font-weight: 500;
      font-size: rem(16);
      line-height: 127.19%;
      color: #bfbfbf;
    }

    &-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + label {
        &::before {
          background-color: $text-blue-green;
        }
      }
    }

    &-checkbox + label {
      display: flex;
      align-items: center;
      user-select: none;
      font-weight: 400;
      font-size: rem(16);
      line-height: 127.19%;

      &::before {
        content: "";
        display: inline-block;
        width: rem(21);
        height: rem(21);
        flex-shrink: 0;
        flex-grow: 0;
        border: none;
        margin-right: rem(20);
        background: $text-gray-placeholder;
      }
    }

    &-radio {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + label {
        &::before {
          background-color: $text-blue-green;
        }
      }
    }

    &-radio + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      font-weight: 400;
      font-size: rem(16);
      line-height: 127.19%;

      &::before {
        content: "";
        display: inline-block;
        width: rem(21);
        height: rem(21);
        flex-shrink: 0;
        flex-grow: 0;
        border: none;
        border-radius: 50%;
        margin-right: rem(20);
        background: $text-gray-placeholder;

      }
    }
  }

  &__complete {
    flex: 1 1 100%;
    margin-left: rem(-32);

    @media (max-width: 1200px) {
      margin-left: rem(-26);
    }

    button {
      font-weight: 400;
      font-size: rem(24);
      line-height: 127.19%;
      padding: rem(4) rem(14) rem(8);
      border: 1px solid $text-blue-green;
      box-shadow: 0px rem(4) rem(4) rgba(0, 0, 0, 0.25);
      border-radius: rem(7);
      cursor: pointer;
      color: black;
    }
  }
}
