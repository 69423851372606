.nav {
  background: $main-bg;
  height: 100%;
  padding: rem(115px) 0 rem(20px);
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .3s ease-in-out;
  width: 100%;
  z-index: 4;
  @media screen and (min-width: 600px) {
    max-width: rem(375px);
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }

  [data-state='nav_open'] & {
    transform: translateX(0);
  }

  .container {
    height: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &.hide {
      display: none;
    }
  }

  &__menu {
  }

  &__list {
    @include list-reset;

    display: grid;
    gap: rem(20px);
  }

  &__li {
    display: flex;
    justify-content: start;
  }

  &__link, &__logout-btn {
    color: #000;
    font-size: rem(22px);
    font-weight: 500;
    letter-spacing: -0.025em;
    line-height: 1;
  }

  &__logout-btn {
    width: min-content;
    cursor: pointer;

    &:hover {
      color: #3ea192;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    gap: rem(20px);
  }

  &__user-btn {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: rem(20px) 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: rem(22px);
    line-height: rem(22px);
    letter-spacing: -0.025em;
    margin-bottom: rem(40px);
    color: #000;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__user-icon-wrapper {
    width: rem(40px);
    height: rem(40px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: rem(4px);
    margin-right: rem(16px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__user-icon {
    height: rem(16px);
  }
}

.nav-darker {
  background: rgba(0, 0, 0, 0.25);
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s;
  visibility: hidden;
  width: 100vw;
  z-index: 3;
  @media screen and (min-width: 1200px) {
    display: none;
  }

  [data-state='nav_open'] & {
    opacity: 1;
    pointer-events: auto;
    transition: opacity .3s;
    visibility: visible;
  }
}
