* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: visible;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

html,
body {
  min-height: 100vh;
  color: $text-default;
  background-color: $background;
  font-family: $ff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-default;
}

@import "./button.scss";
@import "./containers.scss";
@import "./fullpage.scss";
@import "./header.scss";
@import "./header-user.scss";
@import "./footer.scss";
@import "./popup.scss";
@import "./welcome.scss";
@import "./procedure.scss";
@import "./courses.scss";
@import "./materials.scss";
@import "./theory.scss";
@import "./test.scss";
@import "./onboarding.scss";
@import "./checkbox.scss";
@import "./nav.scss";
@import "./breadcrumbs.scss";

// Классы на страницаx, которые были на боевом, но в вёрстке и дизайне нет
.results {
  &__wrapper {
    overflow: auto;

    &>div {
      min-width: 900px;
    }
  }
}

.help-block-error {
  font-size: 12px;
  color: #e9501b;
  margin-top: 5px;
}

.gray {
  color: rgba(0, 0, 0, 0.26);
}

.profile form {
  margin-top: rem(40);
}

.profile .form-group .bmd-label-static {
  top: rem(-20);
}

.profile #reset-password-form .form-group {
  padding-bottom: rem(10);
}

.site-login .card [class*="card-header-"]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
  margin-top: rem(10);
}

@media (max-width: 768px) {
  .profile {
    &__btns {
      max-width: rem(350);
      display: inline-flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 586px) {
  .profile {
    &__btns {
      max-width: rem(325);
    }
  }
}

@media (max-width: 434px) {
  .profile {
    form {
      top: 140px !important;
    }
  }
}

.layout {
  flex: 1 1 100%;
  margin-top: rem(100px);
  margin-bottom: rem(100px);

  @media (max-width: 1024px) {
    margin-top: rem(50px);
    margin-bottom: rem(50px);
  }
}