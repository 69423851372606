.materials {
  margin: rem(100px) 0;
  //font-family: "DINPro";
  color: #000;

  @media (max-width: 768px) {
    margin: rem(60px) 0;
  }

  &__container {
    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
    }
  }

  &__nav-wrapper {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      margin: 0 rem(16px);
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(60px);
    line-height: rem(66px);
    margin-right: 40px;

    @media (max-width: 768px) {
      font-size: rem(35);
      line-height: rem(40);
    }
  }

  &__slider-container {
    margin-top: rem(40px);

    @media (max-width: 768px) {
      margin-top: rem(20px);
    }
  }

  &__slide {
    width: rem(380px) !important;
    padding-right: rem(100px);
    min-height: rem(146px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-child {
      padding-right: 0;
      width: rem(280px) !important;
    }

    @media (max-width: 768px) {
      width: rem(254px) !important;
      padding-right: rem(50px);

      &:first-child {
        margin-left: rem(16px);
      }

      &:last-child {
        width: rem(204px) !important;
        margin-right: rem(16px) !important;
      }
    }
  }

  &__slide-doc-type {
    font-size: rem(18px);
    line-height: rem(26px);
    margin-bottom: rem(10px);
  }

  &__slide-title {
    font-weight: 500;
    font-size: rem(26px);
    line-height: rem(30px);
    letter-spacing: -0.025em;
    margin-bottom: rem(26px);
  }

  &__slide-link-icon {
    margin-top: 2px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-left: 10px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #000;
    stroke: #000;
    transition: border .3s, background-color .3s, color .3s;    
  }

  &__slide-link {
    color: #000;
    display: flex;
    align-items: center;
    font-size: rem(18px);
    line-height: rem(26px);
    transition: .3s;

    &:hover,&:focus{
      color: #000;

      .materials__slide-link-icon{
        border-color: #000;
        background-color: #000;
        color: #fff;
      }
    }

    &:disabled{
      opacity: 0.3;
    }
  }
}
