.footer {
  background: #f0f0f0;
  box-sizing: border-box;
  padding: rem(32px) 0;
  text-align: left !important;

  @media screen and (max-width: 767px) {
    text-align: center !important;
  }

  @media screen and (min-width: 768px) {
    padding: rem(60px) 0;
  }

  &__inner {
    display: grid;
    gap: rem(24px);

    @media screen and (min-width: 768px) {
      gap: 0;
      grid-template-columns: 1fr 1fr 2fr;
    }
  }

  &__col {
  }

  &__link {
    @include link;

    color: $text-default;
    text-decoration: none;

    &:hover {
      color: $text-blue-green;
    }

    &[href^='https://t.me']::after
    {
      content: "";
      display: inline-block;
      width: rem(30);
      height: rem(30);
      background-image: url(../img/tg.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: rem(24px) auto;
      margin-left: rem(6px);
      vertical-align: sub;
    }
  }

  &__text {
    font-size: rem(18px);
    letter-spacing: -0.025em;
    line-height: rem(25px);
    margin: 0;
    opacity: 0.4;

    @media screen and (min-width: 768px) {
      max-width: rem(630px);
      text-align: left;
    }

    @media screen and (max-width: 1024px) {
      br {
        display: none;
      }
    }

    & + & {
      margin-top: rem(30px);
    }
  }
}

.footer-not-auth {
  &__title {
    font-weight: 400;
    font-size: rem(70px);
    line-height: rem(90px);
    color: $title-gray;
    margin-bottom: rem(46px);

    @media screen and (max-width: 768px) {
      font-size: rem(50px);
      line-height: rem(60px);
      margin-bottom: rem(20px);
    }
  }

  &__links-wrapper {
    display: flex;
    margin-bottom: rem(84px);

    @media screen and (max-width: 768px) {
      margin-bottom: rem(40px);
    }
  }

  &__link {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(30px);
    color: $title-gray;
    text-decoration: underline;

    @media screen and (max-width: 768px) {
      font-size: rem(20px);
      line-height: rem(24px);
    }

    & + & {
      margin-left: rem(68px);

      @media screen and (max-width: 768px) {
        margin-left: rem(30px);
      }
    }

    &--tg {
      padding-left: rem(60px);
      background-image: url(../img/tg-grey.svg);
      background-position: center left;
      background-size: rem(36px);
      background-repeat: no-repeat;

      @media screen and (max-width: 768px) {
        padding-left: rem(36px);
        background-size: rem(20px);
      }
    }
  }

  &__text {
    font-size: rem(14px);
    line-height: rem(16px);
    color: $text-gray;
    max-width: rem(790px);
  }
}
