.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-position-x: rem(22);
  max-width: calc(100% - 30px);
  border-radius: rem(5px);
  pointer-events: none;
  visibility: hidden;
  width: 100%;
  max-height: calc(100vh - 6rem);

  display: flex;
  flex-direction: column;
  @media screen and (min-width: 920px) {
    max-width: rem(765px);
  }

  &.active {
    transform: translate(-50%, -50%) scale(1);
    transition: 0.5s all ease-in-out;
    background: #fff;
    pointer-events: all;
    visibility: visible;
  }

  &--loader.active {
    background: none;
  }

  &__inner {
    padding: rem(30);

    @media screen and (min-width: 768px) {
      padding: rem(60);
    }
    @media screen and (min-width: 920px) {
      max-width: rem(765px);
      padding: rem(60);
    }
  }

  &__scroll {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all ease-in-out;
    z-index: 6;
    backdrop-filter: blur(12.5px);

    &.active {
      opacity: 1;
      pointer-events: all;
      transition: 0.5s all ease-in-out;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  & .alert {
    padding: 0;
    margin: 0;
    color: #e11f0c;
  }
}
.popup,
.static-form {
  &__title {
    color: #000;
    font-size: rem(20px);
    font-weight: 500;
    letter-spacing: -0.035em;
    line-height: rem(24px);
    text-align: center;
    margin-bottom: rem(12px);
    //max-width: rem(360px);

    @media screen and (min-width: 768px) {
      font-size: rem(40px);
      line-height: rem(45px);
      margin-bottom: rem(32px);
    }
  }

  &__descr {
    color: #000000;
    font-size: rem(18px);
    letter-spacing: -0.015em;
    line-height: rem(25px);
    max-width: rem(330px);
    text-align: center;
  }

  &__grid {
    display: grid;
    gap: 0 rem(15px);
    width: 100%;

    @media screen and (min-width: 768px) {
      //gap: rem(20px) rem(15px);
      grid-template-columns: 1fr 1fr;

      &--col {
        grid-template-columns: 1fr;
      }
    }
  }

  &__error {
    display: flex;
    justify-content: center;

    .help-block-error {
      margin-top: 0;
    }
  }

  &__field {
    position: relative;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: rem(25px);
    margin-top: rem(40px);
  }

  &__title + &__footer {
    margin-top: rem(20px);
  }

  .error-box {
    color: #ff483d;
    font-size: rem(14px);
    letter-spacing: -0.015em;
    line-height: rem(24px);
    text-align: left;
  }

  .popup__field input {
    appearance: none;
    background: #f5f5f5;
    border: none;
    border-radius: rem(5px);
    box-shadow: none;
    color: #000;
    display: block;
    //font-family: "DINPro";
    font-size: rem(18);
    font-weight: 400;
    height: auto;
    line-height: rem(23);
    margin: 0;
    outline: none;
    padding: rem(17px) rem(20px);

    &[type="password"] {
      letter-spacing: 4px;

      &::placeholder {
        letter-spacing: normal;
      }

      &::-moz-placeholder {
        letter-spacing: normal;
      }
    }

    &:focus {
      background: #f0f0f0;
      border: none;
      box-shadow: none;
    }

    &::placeholder {
      font-size: rem(18);
      line-height: rem(23);
      //color: rgba(0, 0, 0, 0.26) !important;
      color: #c7c7c7 !important;
      opacity: 1 !important;
    }

    &::-moz-placeholder {
      font-size: rem(18);
      line-height: rem(23);
      // color: rgba(0, 0, 0, 0.26) !important;
      color: #c7c7c7 !important;
      opacity: 1 !important;
    }

    &[aria-invalid="true"] {
      outline: rem(1px) solid #ff483d;
    }
  }

  .button {
    padding-left: rem(49px);
    padding-right: rem(49px);
  }

  &__form-forgot {
    background: transparent;
    border: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: block;
    font-size: rem(18px);
    height: auto;
    line-height: rem(23px);
    padding: 0;
    position: relative;
    text-transform: none;

    &::after {
      background: rgba(0, 0, 0, 0.4);
      bottom: 0;
      content: "";
      height: rem(1px);
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &__close {
    cursor: pointer;
    padding: rem(5px);
    position: absolute;
    top: rem(5px);
    right: rem(5px);
    @media screen and (min-width: 768px) {
      top: rem(35px);
      right: rem(55px);
    }

    img {
      display: block;
    }
  }

  &__checkbox {
    max-width: rem(245px);
  }

  .popup__field-pass {
    position: relative;

    .pass-eye {
      display: none;
      opacity: 0.25;
      position: absolute;
      right: rem(20px);
      top: rem(14px);
      z-index: 2;

      &.active {
        opacity: 1;
      }
    }
  }

  &__btn {
    padding-top: rem(14px);
    padding-bottom: rem(14px);
  }
}
