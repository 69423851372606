.fullpage {
    flex: 1 1 100%;
    // @media (max-height: 920px) {
        overflow: visible;

        .swiper-wrapper {
            display: block;
        }
    // }

    // @media (min-height: 920px) {
    //     max-height: calc(100vh - 100px);
    //     overflow: hidden;

    //     section {
    //         height: 100vh;
    //         max-height: 100vh;
    //     }
    // }
}