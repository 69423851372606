@mixin push--auto {
    margin: {
        left: auto;
        right: auto;
    }
}
@mixin dflex {
    display: flex;
}
@mixin dflex-sb {
    display: flex;
    justify-content: space-between;
}
@mixin dflex-sb-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin dflex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin dflex-center-start-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@mixin button-reset {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

@mixin list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@mixin below($width, $orientation: "width") {
    @media screen and (max-#{$orientation}: $width) {
        @content;
    }
}


@mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}

@mixin title {
    font-family: "IBM Medium";
    font-size: rem($fz-title);
}

@function rem($num) {
    $strip-unit: $num / ($num * 0 + 1);
    @return $strip-unit / 16 * 1rem;
}
