$progress-bar-stroke-width: 2;

.courses {
  //margin: rem(21) 0;
  //font-family: "DINPro";
  color: #000;

  &__viewed-wrapper {
    display: flex;
    align-items: center;
    font-size: rem(18px);
    line-height: rem(26px);
    letter-spacing: -0.025em;
    margin-bottom: rem(25px);

    @media (max-width: 768px) {
      font-size: rem(16px);
      margin-bottom: rem(10px);
    }
  }

  &__progress-wrapper {
    height: 20px;
    width: 20px;
    flex: 0 0 20px;
    transform: rotate(-90deg);
    margin-right: 10px;
  }

  &__progress-background {
    fill: none;
    stroke: #d5d5d5;
    stroke-width: $progress-bar-stroke-width;
  }

  &__progress {
    fill: none;
    stroke: #000;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: $progress-bar-stroke-width;
  }

  &__title {
    font-family: $ff;
    font-weight: 500;
    font-size: rem(60);
    line-height: rem(66);
    margin-bottom: rem(40);

    @media (max-width: 768px) {
      font-size: rem(35);
      line-height: rem(40);
      margin-bottom: rem(20);
    }
  }

  &__courses {
    @include list-reset;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: rem(290);
    grid-gap: rem(14);

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-auto-rows: rem(210);
      grid-gap: rem(20);
    }
  }

  &__course-wrapper {
    height: 100%;
  }

  &__course {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: rem(5);
    padding: rem(20);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    text-align: left;
    background: #4AB9A9;
    color: #fff;
    border: 1px solid transparent;
    transition: all 0.4s;

    @media (max-width: 768px) {
      padding: rem(16);
    }

    &:hover {
      background: #43A798;
      color: #fff;
      border-color: transparent;
    }

    &:focus {
      background: #3B9487;
      color: #fff;
      border-color: transparent;
    }

    &:disabled {
      pointer-events: none;
    }

    &--closed {
      background: #F0F0F0;
      color: #000;
      //border-color: rgba(0, 0, 0, 0.2);
    }

    &--locked {
      background: #4AB9A9;
      color: rgba(255, 255, 255, 0.5);

      .courses__course-time:after{
        opacity: 0.5;
      }
    }

    &--paid {
      background: #fff;
      color: #000;
      border-color: rgba(0, 0, 0, 0.2);
    }

    &--paid, &--closed {
      .courses__course-time {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }
      }
    }
  }

  &__course-top {
    display: block;
  }

  &__course-num {
    display: block;
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(26px);
    margin-bottom: rem(10px);
    letter-spacing: -0.025em;
    padding: 0;

    @media (max-width: 768px) {
      font-size: rem(16px);
    }
  }

  &__course-title {
    display: block;
    font-weight: 500;
    font-size: rem(26px);
    line-height: rem(30px);
    letter-spacing: -0.025em;

    @media (max-width: 768px) {
      font-size: rem(22px);
      line-height: rem(22px);
    }
  }

  &__course-time {
    display: block;
    margin-top: rem(7);
    font-size: rem(18px);
    line-height: rem(26px);
    display: flex;
    align-items: center;
    padding-left: rem(30px);
    position: relative;

    @media (max-width: 768px) {
      font-size: rem(16px);
    }

    &:before, &:after {
      position: absolute;
      content: "";
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      transition: 0.4s;
    }

    &:before {
      background-image: url("../img/time.svg");
      opacity: 0;
    }

    &:after {
      background-image: url("../img/time-white.svg");
      opacity: 1;
    }
  }
}
