.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  margin: 0 auto;
  max-width: rem(1440px);
  width: calc(100% - 2rem);

  @media screen and (min-width: 768px) {
    width: calc(100% - 4rem);
  }
}
