$main-bg: #FFFFFF;

$text-blue-green: #4AB9A9;
$text-default: #000000;
$text-invert: #ffffff;
$text-gray: rgba(0, 0, 0, 0.3);
$text-gray-darken: #636363;
$text-gray-placeholder: #C4C4C4;

$title-default: rgba(0, 0, 0, 0.93);
$title-gray: rgba(0, 0, 0, 0.5);