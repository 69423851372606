.theory {
  margin: rem(21) 0;
  margin-top: 0;
  
  &__title {
    //font-family: "DINPro";
    font-weight: 400;
    font-size: rem(70);
    line-height: rem(89);
    color: rgba(0, 0, 0, 0.93);

    @media (max-width: 768px) {
      font-size: rem(35);
      line-height: rem(54);
    }

    @media (max-width: 389px) {
      font-size: rem(25);
      line-height: rem(30);
    }
  }

  &__subtitle {
    //font-family: "DINPro";
    font-weight: 700;
    font-size: rem(55);
    line-height: rem(70);
    color: rgba(0, 0, 0, 0.25);

    @media (max-width: 768px) {
      font-size: rem(30);
      line-height: rem(48);
    }

    @media (max-width: 586px) {
      display: inline-block;
      font-size: rem(30);
      line-height: rem(30);
    }

    @media (max-width: 389px) {
      font-size: rem(18);
      line-height: rem(26);
    }
  }

  &__test {
    margin-top: rem(12);

    a {
      font-weight: 500;
      font-size: 21px;
      line-height: 127.19%;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: rem(-3);
        left: 0;
        width: 100%;
        height: 1px;
        background: $text-blue-green;
        opacity: 1;
        transition: all ease-in-out 0.3s;
      }

      &:hover {
        &::after {
          opacity: 0;
        }
      }
    }
  }

  &__video {
    margin-top: rem(35);
    width: rem(1050);
    height: rem(591);
    background: $text-gray-placeholder;

    @media (max-width: 1100px) {
      width: 100%;
      height: rem(400);
    }

    @media (max-width: 920px) {
      height: rem(250);
      margin-top: rem(24);
    }

    video {
      width: 100%;
      height: auto;
    }
  }

  iframe {
    width: 65.625rem;
    height: 36.9375rem;
    margin-top: rem(35);

    @media (max-width: 1100px) {
      width: 100%;
      height: rem(400);
    }

    @media (max-width: 920px) {
      height: rem(250);
      margin-top: rem(24);
    }
  }

  .popup__result{
    width: 65.625rem;
    text-align: center;
    margin-top: 30px;
    
    @media (max-width: 1100px) {
      width: 100%;
    }
  }
}
