.gl-swiper {
  .gl-swiper-wrapper {
    display: flex;
    align-items: stretch;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.gl-swiper-nav-wrapper {
  display: flex;
}

.gl-swiper-nav {
  cursor: pointer;
  flex: 0 0 rem(56px);
  width: rem(56px);
  height: rem(56px);
  border: 1px solid #000;
  border-radius: 5px;
  background-image: url("../img/arrow.svg");
  background-size: rem(12px);
  background-position: center;
  background-repeat: no-repeat;

  &:disabled, &.swiper-button-disabled {
    opacity: .2;
  }

  &--prev {
    transform: rotate(90deg);
  }

  &--next {
    transform: rotate(-90deg);
  }

  & + & {
    margin-left: 16px;
  }
}

// todo добавить пагинацию
.gl-swiper-pagination-wrapper {
  .swiper-pagination-bullet {
    width: rem(8px);
    height: rem(8px);
    border: none;
    border-radius: 100%;
    background-color: #4AB9A9;
    opacity: 0.4;
    margin-right: rem(10px);
    transition: 0.4s;

    &:last-child {
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #4AB9A9;
    width: rem(18px);
    border-radius: 500px;
    opacity: 1;
  }
}
