@font-face {
  font-family: "DINPro";
  src: url("../fonts/DINPro-Light.woff"), url("../fonts/DINPro-Light.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DINPro";
  src: url("../fonts/DINPro.woff"), url("../fonts/DINPro.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DINPro";
  src: url("../fonts/DINPro-Medium.woff"), url("../fonts/DINPro-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DINPro";
  src: url("../fonts/DINPro-Bold.woff"), url("../fonts/DINPro-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CeraPro";
  src: url("../fonts/CeraPro/CeraPro-Light.woff"), url("../fonts/CeraPro/CeraPro-Light.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CeraPro";
  src: url("../fonts/CeraPro/CeraPro-Regular.woff"), url("../fonts/CeraPro/CeraPro-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CeraPro";
  src: url("../fonts/CeraPro/CeraPro-Medium.woff"), url("../fonts/CeraPro/CeraPro-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CeraPro";
  src: url("../fonts/CeraPro/CeraPro-Bold.woff"), url("../fonts/CeraPro/CeraPro-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CeraPro";
  src: url("../fonts/CeraPro/CeraPro-Black.woff"), url("../fonts/CeraPro/CeraPro-Black.woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
