.procedure {
  padding: rem(200) 0 rem(170px);

  @media (max-width: 920px) {
    padding: rem(60) 0;
  }

  &__wrapper {
    position: relative;
  }

  &__title {
    h2 {
      @include h2;
    }
  }

  &__content {
    max-width: rem(731);
    padding-left: rem(12);

    @media (max-width: 1200px) {
        padding-left: 0;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }
  }

  &__desc {
    margin-top: rem(40);

    @media (max-width: 920px) {
        margin-top: rem(30);
    }

    p {
      @include text;

      margin-bottom: rem(34);

      @media (max-width: 920px) {
        margin-bottom: rem(24)
      }
    }
  }

  &__picture {
    width: rem(455);
    height: rem(763);
    background: url("../img/procedure.png") center/contain no-repeat;
    position: absolute;
    top: rem(-36);
    right: 0;

    @media (max-width: 1200px) {
      width: rem(430);
      height: rem(560);
      top: 0;
      right: rem(-30);
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}
