.welcome {
  padding: rem(32px) 0;
  @media screen and (min-width: 920px) {
    padding: rem(75px) 0 0;
  }
  // @media (min-height: 1080px) {
  //   height: calc(100vh - 198px);
  // }
  &__inner {}
  &__row {
    display: flex;
    flex-direction: column;
    gap: rem(24px);

    @media screen and (min-width: 768px) {
      gap: rem(40px);
    }

    @media screen and (min-width: 920px) {
      align-items: end;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__info {
    @media screen and (min-width: 920px) {
      max-width: rem(468px);
    }
  }
  
  &__video {
    aspect-ratio: 1.615/1;
    background: $text-blue-green;
    border-radius: rem(12px);
    overflow: hidden;
    position: relative;

    video {
      display: inline-block;
      height: 100%;
      vertical-align: top;
      width: 100%;
    }
  }
  &__video-play-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 100%;
    position: absolute;
    transition: opacity .25s ease;
    width: 100%;
    z-index: 2;

    &:hover {
      opacity: .9;
    }
    &[data-button-state="play"] img{
      display: none;
    }

    img {
      height: auto;
      width: rem(64px);

      @media screen and (min-width: 920px) {
        width: rem(90px);
      }
    }
  }

  /*&[data-button-state="play"] .video__play-button-icon{
    opacity:0;
  }

  &[data-button-state="play"]:hover .video__play-button-icon{
    opacity:1;
  }*/

  &__title {
    @include h1_new;

    margin: 0 0 rem(33px);
  }

  &__desc {
    p {
      @include text_new;

      margin: 0;
    }
    p + p {
      margin-top: rem(28);
    }
  }

  &__stat {
    border-top: 1px solid rgba(0, 0, 0, .2);
    display: flex;
    justify-content: space-around;
    margin-top: rem(75px);
    padding-top: rem(35px);

    @media (max-width: 920px) {
      margin-left: 0;
      margin-top: rem(60);
    }

    @media (max-width: 600px) {
      margin-top: rem(32);
      flex-wrap: wrap;
      gap: rem(24);
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__num {
    font-size: 42px;
    line-height: 53px;
  }

  &__descr {
    font-size: 17px;
    line-height: 21px;
  }
}
