.breadcrumbs-custom {
  display: flex;
  align-items: center;
  color: #000;
  //margin-top: rem(20px);
  //margin-bottom: rem(100px);
  margin-bottom: rem(25px);

  // @media (max-width: 1024px) {
  //   margin-bottom: rem(50px);
  // }

  @media (max-width: 768px) {
    display: none;
    margin-bottom: rem(10px);
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: block;
    font-size: rem(18px);
    line-height: rem(26px);
    padding: 0;
    color: #000;
    padding-right: rem(36px);
    position: relative;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      right: rem(14px);
      width: rem(12px);
      height: rem(12px);
      background-image: url("../img/arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateY(-50%) rotate(-90deg);
    }

    &:last-child {
      padding-right: 0;

      &:before {
        content: none;
      }
    }

    &--active {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
