$padding: rem(110px);
$padding-small: rem(80px);

.onboarding {
	//font-family: "DINPro";
	color: #000;
	position: absolute;
	top: $padding;
	width: 100%;
	height: calc(100% - #{$padding} * 2);
	padding: $padding 0;

	@media screen and (max-height: 900px) {
		top: $padding-small;
		height: calc(100% - #{$padding-small} * 2);
		padding: $padding-small 0;
	}

	&__container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		@media screen and (max-height: 900px) {
			height: min-content;
		}
	}

	&__content {
		width: 100%;
	}

	&__swiper-container {
		display: flex;
		align-items: center !important;
	}

	&__slide-wrapper {
		width: 100% !important;
		overflow: hidden;
		display: flex;
		justify-content: center;
	}

	&__slide {
		max-width: rem(520px);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__slide-img {
		width: rem(120px);
		height: rem(120px);
		margin-bottom: rem(40px);

		@media screen and (max-width: 768px) {
			width: rem(100px);
			height: rem(100px);
			margin-bottom: rem(24px);
		}

		@media screen and (max-height: 900px) {
			margin-bottom: rem(24px);
		}
	}

	&__title {
		text-align: center;
		font-weight: 500;
		font-size: rem(40px);
		line-height: rem(44px);
		letter-spacing: -0.035em;
		margin-bottom: rem(24px);

		@media screen and (max-width: 768px) {
			font-size: rem(22px);
			line-height: rem(28px);
			margin-bottom: rem(16px);
		}
	}

	&__caption {
		text-align: center;
		font-size: rem(18px);
		line-height: rem(26px);
		letter-spacing: -0.015em;

		@media screen and (max-width: 768px) {
			font-size: rem(16px);
			line-height: rem(24px);
		}
	}

	&__btn-wrapper {
		margin-top: rem(40px);
		display: flex;
		justify-content: center;

		@media screen and (max-width: 768px) {
			margin-top: rem(24px);
		}

		@media screen and (max-height: 900px) {
			margin-top: rem(24px);
		}
	}

	&__btn {
		width: rem(240px);
		flex: initial;

		&.hide {
			display: none;
		}
	}

	&__pagination-wrapper {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, calc(100% + 60px));
		padding: rem(60px);

		@media screen and (max-height: 900px) {
			padding: 0;
		}
	}

	&__pagination {
		display: flex;
		justify-content: center;
	}
}
